import type { IconLabelProps } from "@enzymefinance/ui";
import { IconLabel } from "@enzymefinance/ui";
import type { ReactNode } from "react";

import type { VaultIconProps } from "./VaultIcon";
import { VaultIcon } from "./VaultIcon";

export interface VaultLabelProps
  extends Omit<IconLabelProps, "size" | "title">,
    Pick<VaultIconProps, "deployment" | "id" | "size"> {
  name: ReactNode;
  reverse?: boolean;
}

export function VaultLabel({
  description,
  deployment,
  id,
  kind,
  name,
  reverse = false,
  size,
  ...props
}: VaultLabelProps) {
  return (
    <IconLabel
      reverse={reverse}
      description={
        kind === "definition" || kind === "option" ? <span aria-hidden={true}>{description}</span> : description
      }
      icon={<VaultIcon id={id} deployment={deployment} size={size} />}
      space={size === 3 || size === 4 || size === 5 ? 2 : 4}
      title={name}
      {...props}
    />
  );
}
